import React from 'react';

export default function TimeBadges(props) {
  const matchingArrivals = props.stopArrivals
    .filter(arrival => props.line.routeCodes.includes(arrival.routeCode))

  if ( matchingArrivals.length > 0 ) {
    let arrivalsForLine = {
      liveBusesMinutes: [],
      nextBusBeginsAt: [],
    };

    for(const arrivalData of matchingArrivals) {
      if ( arrivalData.liveBusesMinutes.length ) {
        arrivalsForLine.liveBusesMinutes = arrivalsForLine.liveBusesMinutes.concat(arrivalData.liveBusesMinutes);
      }
      if ( arrivalData.nextBusBeginsAt ) { arrivalsForLine.nextBusBeginsAt.push(arrivalData.nextBusBeginsAt); }
    }

    if ( arrivalsForLine.liveBusesMinutes.length ) {
      const sortedMinutes = arrivalsForLine.liveBusesMinutes.sort((a, b) => parseInt(a) <= parseInt(b) ? -1 : 0);

      return (
        <>
          {sortedMinutes.map((minutes, j) => (
            <span className="time-badge" key={`${props.line.lineCode}-arrival-${j}`}>
              { minutes }΄
            </span>
          ))}

          {arrivalsForLine.nextBusBeginsAt.length ?
            <>
              {arrivalsForLine.nextBusBeginsAt.sort().map((time, j) => (
                <span key={`${props.line.lineCode}-next-at-${j}`} className="time-badge">
                  από αφετηρία στις { time }
                </span>
              ))}
            </>
          : ""}
        </>
      )
    } else {
      return (
        <>
          {arrivalsForLine.nextBusBeginsAt.sort().map((time, j) => (
            <span className="time-badge" key={`${props.line.lineCode}-not-on-road-${j}`}>
              ξεκινάει στις { time }
            </span>
          ))}
        </>
      )
    }
  } else {
      return (
        <span className="time-badge" key={`${props.line.lineCode}-not-on-road`}>
          δεν έχει ξεκινήσει
        </span>
      )
  }
}
