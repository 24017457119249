import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import LineList from './LineList'
import LineDetails from './LineDetails'
import StopDetails from './StopDetails'
import NotFound from './NotFound'

export default function App(props) {
  return (
    <>
      <h1 style={ { color: "#f0f0f0", textAlign: "center" } }>Λεωφορεία Αθήνας</h1>
      <div className="container">
        <Router>
          <Switch>
            <Route exact path="/" component={LineList}></Route>
            <Route path="/line/:lineCode" component={LineDetails}></Route>
            <Route path="/stop/:stopCode" component={StopDetails}></Route>
            <Route component={NotFound}></Route>
          </Switch>
        </Router>
      </div>
      <div className="scroll-suggestion"></div>
    </>
  );
}
