import axios from 'axios';

export default class OasaAPI {
  static proxify(url) {
    const proxy = `${process.env.REACT_APP_PROXY_URL}?url=`;

    return `${proxy}${encodeURIComponent(url)}`;
  }

  static getLines() {
    let url = this.proxify("http://telematics.oasa.gr/api/?act=webGetLinesWithMLInfo");

    return axios(url)
      .then( response => {
        let lines = response.data;

        return lines.map((line) => {
          return {
            code: line.line_code,
            id: line.line_id,
            descr: line.line_descr,
            descrEng: line.line_descr_eng
          }
        });
      });
  }

  static getLineDetails(lineCode) {
    let url = this.proxify(`http://telematics.oasa.gr/api/?act=getLineName&p1=${lineCode}`);

    return axios(url)
      .then( response => {
        if ( response.data == null ) { return null; }

        // @Note Oasa returns this as an array. Why you ask? Because why the
        //      fuck not apparently. We're just grabbing the first one.
        let lineDetails = response.data[0];

        return {
          id: lineDetails.line_id,
          descr: lineDetails.line_descr,
          code: lineCode,
        }
      });
  }

  static getRoutesForLine(lineCode) {
    let url = this.proxify(`http://telematics.oasa.gr/api/?act=getRoutesForLine&p1=${lineCode}`);

    return axios(url)
      .then( response => {
        // @Note Response data comes back null if the lineCode doesn't exist
        let routes = response.data || [];
        routes = routes.filter(r => r.route_active === "1");

        return routes.map((route) => {
          return {
            code: route.route_code,
            descr: route.route_descr
          }
        });
      });
  }

  static getStops(routeCode) {
    let url = this.proxify(`http://telematics.oasa.gr/api/?act=webGetRoutesDetailsAndStops&p1=${routeCode}`);

    return axios(url)
      .then( response => {
        let stops = response.data.stops;
        let path = response.data.details;

        let stopsObject = stops.map((stop) => {
          return {
            id: stop.StopID,
            code: stop.StopCode,
            descr: stop.StopDescr,
            descrEng: stop.StopDescrEng,
            lat: parseFloat(stop.StopLat),
            lng: parseFloat(stop.StopLng)
          }
        });

        let pathObject = path.map((point) => {
          return {
            lat: parseFloat(point.routed_y),
            lng: parseFloat(point.routed_x)
          }
        });

        return {
          stops: stopsObject,
          path: pathObject
        }
      });
  }

  static getStopDetails(stopCode) {
    let url = this.proxify(`http://telematics.oasa.gr/api/?act=getStopNameAndXY&p1=${stopCode}`);

    return axios(url)
      .then( response => {
        if ( response.data == null ) { return null; }

        // @Note Oasa returns this as an array. Why you ask? Because why the
        //      fuck not apparently. We're just grabbing the first one.
        const stop = response.data[0];

        return {
          id: stop.stop_id,
          code: stopCode,
          descr: stop.stop_descr,
          lat: parseFloat(stop.stop_lat),
          lng: parseFloat(stop.stop_lng),
        }
      });
  }


  static getBusLocations(routeCode) {
    let url = this.proxify(`http://telematics.oasa.gr/api/?act=getBusLocation&p1=${routeCode}`);

    return axios(url)
      .then( response => {
        let busLocations = response.data;

        // @Note When no bus locations are available OASA helpfully returns an empty string.
        if ( busLocations === "" ) { busLocations = [] }

        return busLocations.map((bus) => {
          return {
            vehicleNo: bus.VEH_NO,
            lat: parseFloat(bus.CS_LAT),
            lng: parseFloat(bus.CS_LNG)
          }
        });
      });
  }

  static getStopArrivals(stopCode) {
    let url = this.proxify(`http://telematics.oasa.gr/api/?act=getStopArrivalsAlt&p1=${stopCode}`);

    return axios(url)
      .then( response => {
        const stopArrivals = response.data.routes;

        return stopArrivals.map((arrival) => {
          return {
            routeCode: arrival.route,
            nextBusBeginsAt: arrival.next,
            liveBusesMinutes: arrival.arrivals.map(a => a.time)
          }
        })
      });
  }

  static getStopRoutes(stopCode) {
    let url = this.proxify(`http://telematics.oasa.gr/api/?act=webRoutesForStop&p1=${stopCode}`);

    return axios(url)
      .then( response => {
        if ( response.data == null ) { return []; }

        let stopRoutes = response.data.filter(s => s.hidden !== "1");

        let stopLines = [];
        let seenLineCodes = [];

        stopRoutes.forEach((route) => {
          let newLineAt = seenLineCodes.indexOf(route.LineCode);

          // @Note Duplicate routeCodes per line might be seen. They have a different
          // master line code, which I'm not sure what it actually means.
          if (newLineAt === -1) {
            let newLineData = {
              id: route.LineID,
              code: route.LineCode,
              descr: route.LineDescr,
              routeCodes: [route.RouteCode]
            }

            seenLineCodes.push(route.LineCode);
            stopLines.push(newLineData);
          } else {
            let existingLineData = stopLines[newLineAt];
            existingLineData.routeCodes.push(route.RouteCode);
          }
        });

        return stopLines;
      });
  }

  static getClosestStops(lat, lng) {
    let url = this.proxify(`http://telematics.oasa.gr/api/?act=getClosestStops&p1=${lat}&p2=${lng}`);

    return axios(url)
      .then( response => {
        const stops = response.data;

        return stops.map((stop) => {
          return {
            id: stop.StopID,
            code: stop.StopCode,
            descr: stop.StopDescr,
            lat: parseFloat(stop.StopLat),
            lng: parseFloat(stop.StopLng),
          }
        });
      });
  }
}
