const LOCAL_STORAGE_KEY = "favorite-lines";

export default class FavoriteLines {
  static store(lineCode, lineName) {
    const valueToStore = `${lineCode}:${lineName}`;
    const storedLinesStr = localStorage.getItem(LOCAL_STORAGE_KEY);

    if ( storedLinesStr ) {
      let storedLines = storedLinesStr.split(",");

      // @Note Remove any badly formed line
      storedLines = storedLines.filter(line => line.includes(":"))

      if ( !storedLines.includes(valueToStore) ) {
        storedLines.push(valueToStore);
      }

      localStorage.setItem(LOCAL_STORAGE_KEY, storedLines.join(","));
    } else {
      localStorage.setItem(LOCAL_STORAGE_KEY, valueToStore);
    }
  }

  static includes(lineCode) {
    return FavoriteLines.all().find(l => l.split(":")[0] === lineCode);
  }

  static delete(lineCode) {
    const lines = FavoriteLines.all().filter(l => l.split(":")[0] !== lineCode);
    localStorage.setItem(LOCAL_STORAGE_KEY, lines.join(","));
  }

  static clear() {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  }

  static all() {
    const storedLinesStr = localStorage.getItem(LOCAL_STORAGE_KEY);

    if ( storedLinesStr ) {
      return storedLinesStr.split(",");
    } else {
      return []
    }
  }
}
