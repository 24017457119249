import React from 'react';
import { Icon as LeafletIcon } from 'leaflet';
import { Map , TileLayer, Marker, Popup } from 'react-leaflet';
import AntPath from 'react-leaflet-ant-path';

import LiveBusMarkers from './LiveBusMarkers';

import UserPin from 'images/user.svg'
import ImgPin from 'images/pin.svg'
import ImgPinRed from 'images/pin_red.svg'

const mapStyle = {
  height: '400px',
  width: '100%'
};

const markerIcon = new LeafletIcon({
  iconUrl: ImgPin,
  iconSize: [15, 15],
  iconAnchor: [5, 10],
  popupAnchor: [3, -15],
});

const selectedMarkerIcon = new LeafletIcon({
  iconUrl: ImgPinRed,
  iconSize: [20, 20],
  iconAnchor: [5, 10],
  popupAnchor: [3, -15],
});

const userLocationIcon = new LeafletIcon({
  iconUrl: UserPin,
  iconSize: [30, 30],
  iconAnchor: [10, 30],
});

export default class StopsMap extends React.Component {
  static defaultProps = {
    onStopSelect: () => {}
  }

  calculateMapCenter() {
    if ( this.props.selectedStop ) {
      return { lat: this.props.selectedStop.lat, lng: this.props.selectedStop.lng, zoom: 16 }
    } else {
      let stopCoords = this.props.stops.map(s => ({ lat: s.lat, lng: s.lng }));
      let minLat = stopCoords.reduce((acc, cur) => Math.min(acc, cur.lat), 90);
      let maxLat = stopCoords.reduce((acc, cur) => Math.max(acc, cur.lat), -90);

      let minLng = stopCoords.reduce((acc, cur) => Math.min(acc, cur.lng), 180);
      let maxLng = stopCoords.reduce((acc, cur) => Math.max(acc, cur.lng), -180);

      let centerLat = (minLat + maxLat) * 0.5;
      let centerLng = (minLng + maxLng) * 0.5;

      return { lat: centerLat, lng: centerLng, zoom: 13 };
    }
  }

  getLatLngs() {
    // @Note Make sure we return at least one point at all times to AntPath
    let latLngs = [[0, 0]];

    this.props.selectedRoutePath.forEach((point, i) =>
      latLngs[i] = [point.lat, point.lng]
    );

    return latLngs;
  }

  selectStopAndMaybeSuggestScrolling(stopCode) {
    const stopsEl = document.querySelector(".stops");

    // @Note If the stop information section is not visible on screen, we
    // enable the scroll suggestion animation to let the user know that
    // something happened lower on the page if they scroll down.
    if ( stopsEl && stopsEl.getBoundingClientRect().top > window.innerHeight ) {
      const scrollSuggestion = document.querySelector(".scroll-suggestion");
      if ( scrollSuggestion ) {
        scrollSuggestion.classList.add("animating");
        scrollSuggestion.addEventListener("animationend", () => {
          scrollSuggestion.classList.remove("animating");
        }, { once: true })
      }
    }

    this.props.onStopSelect(stopCode)
  }

  render() {
    let center = this.calculateMapCenter();

    return (
      <>
        <span id="map"></span>
        <Map center={[center.lat, center.lng]} zoom={center.zoom} style={mapStyle}>
          <TileLayer
            attribution={ "&copy; <a href=\"http://osm.org/copyright\">OpenStreetMap</a> contributors" }
            minZoom={10}
            url="https://{s}.tile.thunderforest.com/atlas/{z}/{x}/{y}@2x.png?apikey=1d2dbc7e815946ec8b6c2105b825fa9c"
          />

          {this.props.selectedRoutePath ?
            <AntPath positions={this.getLatLngs()} options={{ color: "green" }} /> : ""}

          {this.props.stops.map((stop, i) => {
            return (
              <Marker
                zIndexOffset={999}
                icon={(this.props.selectedStop || {}).code === stop.code ? selectedMarkerIcon : markerIcon}
                key={`marker-${i}-${stop.code}`}
                onClick={() => { this.selectStopAndMaybeSuggestScrolling(stop.code) }}
                position={[stop.lat, stop.lng]}>
                <Popup autoPan={false}>
                  {stop.id} - {stop.descr}
                </Popup>
              </Marker>
            )
          })}

          {this.props.userLocation ?
            <Marker zIndexOffset={-1} icon={userLocationIcon} position={this.props.userLocation} /> : ""
          }

          {this.props.selectedRouteCode ?
            <LiveBusMarkers selectedRouteCode={this.props.selectedRouteCode} /> : ""}
        </Map>

        {this.props.withLocationError ?
          <div className="small-error">δυσκολευόμαστε να βρούμε την τοποθεσία σας :(</div>: ""
        }
      </>
    );
  }
}
