import React from 'react'

export default function Loader(props) {
  if ( props.isLoading ) {
    return (
      <div className="loading">
        φορτώνει...
      </div>
    )
  }

  return props.children || "";
}
