import React from 'react';


export default class Stops extends React.Component {
  state = {
    quickSearchText: "",
    typingTimeout: null
  };

  handleQuickSearchChange = (e) => {
    let inputText = e.target.value;

    clearTimeout(this.typingTimeout);
    this.typingTimeout = setTimeout(() => {
      this.setState({ quickSearchText: inputText });
    }, 200);
  }

  filteredStops = () => {
    if ( this.state.quickSearchText && this.state.quickSearchText.length > 0 ) {
      const searchText = this.state.quickSearchText.toUpperCase().trim();

      return this.props.stops.filter(stop => {
        return stop.descr.toUpperCase().includes(searchText) ||
          stop.descrEng.toUpperCase().includes(searchText);
      })
    } else {
      return this.props.stops;
    }
  }

  render() {
    return (
      <>
        <input
          type="text"
          name="quick-search"
          className="quick-search small"
          placeholder="αναζήτηση"
          autoComplete="off"
          onChange={this.handleQuickSearchChange} />

        <hr/>

        <div className="stops-list">
          {this.filteredStops().map((stop, i) => {
            return (
              <div key={`stop-${i}-${stop.code}`} className="stop">
                <button
                  className="btn-link"
                  onClick={() => this.props.onStopSelect(stop.code)}
                >
                  { this.props.selectedStop && stop.code === this.props.selectedStop.code ? " > " : "" }
                  {stop.id} - {stop.descr}
                </button>
                { this.props.selectedStop && stop.code === this.props.selectedStop.code ?
                  <a className="to-map" href="#map">(στο χάρτη)</a>
                  : "" }
              </div>
            )
          })}
        </div>
      </>
    );
  }
}
