import React from 'react';
import { Link } from 'react-router-dom';

import Loader from './Loader';
import Stops from './Stops';
import StopsMap from './StopsMap';
import LiveStopFeed from './LiveStopFeed';
import RouteSelector from './RouteSelector';
import ErrorBoundary from './ErrorBoundary';

import FavoriteLines from 'storage/favorite_lines';
import OasaAPI from 'third_party/oasa_api';

import { ReactComponent as Star } from 'images/star.svg';

export default class LineDetails extends React.Component {
  state = {
    networkError: false,
    notFound: false,
    line: null,
    isFavorite: false,
    routes: [],
    selectedRouteCode: null,
    selectedRoutePath: [],
    stops: [],
    selectedStopCode: null,
    linesFromSelectedStop: [],
    stopsLoaded: false,
    // @Note We assume it's loaded since no stop has been selected yet. Avoids
    // "loading..." on first page load for the stop feed.
    stopFeedLoaded: true,
    userLocation: null,
    withLocationError: false,
    closestStopToUser: null,
  }

  changeLine = (lineCode, preferredStopCode = null) => {
    if ( this.state.line == null || lineCode !== this.state.line.code ) {
      // @Note When changing a line, the following code tries to retain the
      // selected Stop if possible. This happens later, by getting the selected
      // stop's routes, and checking if the line we're switching to has any of
      // them, and we select it if so.

      if ( preferredStopCode == null ) { preferredStopCode = this.state.selectedStopCode; }
      this.setState({
        isFavorite: FavoriteLines.includes(lineCode),
        selectedStopCode: null,
        linesFromSelectedStop: []
      });

      OasaAPI.getLineDetails(lineCode)
        .then( line => {
          if ( line ) {
            this.setState({line: line})
          } else {
            FavoriteLines.delete(lineCode);
            this.setState({ notFound: true })
          }
        }).catch( e => this.setState({ networkError: true }) )

      OasaAPI.getRoutesForLine(lineCode).then( routes => {
        this.setState({ routes: routes }, () => {
          let preferredRouteCode = routes.length ? routes[0].code : null;

          if ( preferredStopCode ) {
            // @Optimize We can probably cache the result of this section, since the
            // stopLine.routeCodes will be constant until the selectedStop changes.
            // We can probably remove preferredStopCode, and just use selectedStopCode tbh.
            OasaAPI.getStopRoutes(preferredStopCode).then(stopLines => {
              const currentRouteCodes = this.state.routes.map(route => route.code);
              const stopLine = stopLines.find(stopLine => stopLine.code === lineCode);

              if ( stopLine ) {
                for(const stopRouteCode of stopLine.routeCodes) {
                  if ( currentRouteCodes.includes(stopRouteCode) ) {
                    preferredRouteCode = stopRouteCode;
                    break;
                  }
                }
              }

              if ( preferredRouteCode ) {
                this.changeRoute(preferredRouteCode, preferredStopCode);
              }
            })
          } else {
            this.changeRoute(preferredRouteCode);
          }
        })
      });

      if ( preferredStopCode ) {
        this.props.history.push(`/line/${lineCode}?stop_code=${preferredStopCode}`);
      } else {
        this.props.history.push(`/line/${lineCode}`);
      }
    }
  }

  changeRoute = (routeCode, preferredStopCode = null) => {
    // @Note User can pass true to mean "Keep the selected stop if possible"
    if ( preferredStopCode == true ) {
      preferredStopCode = this.state.selectedStopCode;
    }

    if ( this.state.selectedRouteCode !== routeCode ) {
      this.setState({
        selectedStopCode: null,
        selectedRouteCode: routeCode,
        stopsLoaded: false
      }, () => {
        OasaAPI.getStops(this.state.selectedRouteCode)
          .then( stopsAndPath => {
            this.setState({
              stops: stopsAndPath.stops,
              selectedRoutePath: stopsAndPath.path,
              stopsLoaded: true,
            }, () => {
              if ( preferredStopCode ) {
                const preferredStop = this.state.stops.find(s => s.code === preferredStopCode);
                if ( preferredStop ) {
                  this.changeStop(preferredStop.code);
                }
              }
            })
          });
      });
    }
  }

  changeStop = (stopCode) => {
    this.setState({ stopFeedLoaded: false });
    OasaAPI.getStopRoutes(stopCode).then( stopLines => {
      this.setState({
        selectedStopCode: stopCode,
        linesFromSelectedStop: stopLines,
        stopFeedLoaded: true,
      });
    })
  }

   setStopInURL = () => {
     const query = new URLSearchParams(this.props.location.search);
     const stopCode = this.state.selectedStopCode;

     stopCode ? query.set('stop_code', stopCode) : query.delete('stop_code');
     this.props.history.replace({...this.props.history.location, search: query.toString()});
  }

  handleFavorite = () => {
    const lineCode = this.state.line.code;

    if ( FavoriteLines.includes(lineCode) ) {
      FavoriteLines.delete(lineCode);
      this.setState({ isFavorite: false })
    } else {
      FavoriteLines.store(lineCode, this.state.line.id);
      this.setState({ isFavorite: true })
    }
  }

  findClosestStop = (latitude, longitude) => {
    // @Note We don't want to show the closest stop if it's too far.
    // This number corresponds to a maximum distance of around 1.5km,
    // which should be acceptable I think.
    let minDistance = 0.0002041993000000373;
    let closestStop = null;

    for(const stop of this.state.stops) {
      const distToUser =
        (stop.lat - latitude) * (stop.lat - latitude) +
        (stop.lng - longitude) * (stop.lng - longitude)

      if ( distToUser < minDistance ) {
        minDistance = distToUser;
        closestStop = stop;
      }
    }

    return closestStop;
  }

  componentDidMount() {
    const lineCode = this.props.match.params.lineCode;

    const query = this.props.location.search;
    const params = new URLSearchParams(query);
    const preferredStopCode = params.get("stop_code");

    this.changeLine(lineCode, preferredStopCode);

    this.geolocationId = navigator.geolocation.watchPosition((pos) => {
      const coords = pos.coords;
      const closestStop = this.findClosestStop(coords.latitude, coords.longitude);

      this.setState({
        userLocation: [ coords.latitude, coords.longitude ],
        withLocationError: false,
        closestStopToUser: closestStop
      })
    }, (err) => {
      this.setState({ withLocationError: true })
    }, {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    });
  }

  componentWillUnmount() {
    navigator.geolocation.clearWatch(this.geolocationId);
  }

  componentDidUpdate(prevProps, prevState) {
    if ( prevState.selectedStopCode !== this.state.selectedStopCode ) {
      this.setStopInURL();
    }
  }

  render() {
    if ( this.state.notFound || this.state.networkError ) {
      return <>
        <div className="line-details-header">
          <div>
            <Link to={`/`}>επιστροφή</Link>
          </div>
        </div>

        <div className="error-box">
          { this.state.notFound ?
              "Δεν υπάρχει αυτή η γραμμή :(" :
              "Κάτι πήγε στραβά και δεν μπορέσαμε να φορτώσουμε το περιεχόμενο που βρισκόταν εδώ."
          }
        </div>
      </>
    }

    if ( this.state.line ) {
      const selectedStop = this.state.stops.find(s => s.code === this.state.selectedStopCode);

      return (
        <>
          <div className="line-details-header">
            <div>
              <Link to={`/`}>επιστροφή</Link>
            </div>
            <div className="line-details">
              <div className="line-id">
                <span
                  title={this.state.isFavorite ? "αφαίρεση από τα αγαπημένα" : "προσθήκη στα αγαπημένα"}
                  onClick={this.handleFavorite}>
                  <Star className={`favorite-star ${this.state.isFavorite ? "active" : ""}`} />
                </span>
                { this.state.line.id }
              </div>
              <div className="line-desc">{ this.state.line.descr }</div>
          <div className="scroll-to-stops">
            <a href="#scroll-to-stops-point">ΔΕΙΤΕ ΤΙΣ ΣΤΑΣΕΙΣ</a>
          </div>
            </div>

          </div>

          <ErrorBoundary>
            <div className="title">Επιλέξτε δρομολόγιο:</div>
            <RouteSelector
              routes={this.state.routes}
              selectedRouteCode={this.state.selectedRouteCode}
              onRouteSelect={this.changeRoute}
            />
          </ErrorBoundary>

          <hr/>
          <ErrorBoundary>
            <StopsMap
              userLocation={this.state.userLocation}
              withLocationError={this.state.withLocationError}
              stops={this.state.stops}
              selectedStop={selectedStop}
              selectedRouteCode={this.state.selectedRouteCode}
              selectedRoutePath={this.state.selectedRoutePath}
              onStopSelect={this.changeStop}
            />
          </ErrorBoundary>
          <hr/>

          <div className="stops">
            <div className="stops-col">
              <div className="title">Τηλεματική</div>
              {!this.state.withLocationError && this.state.closestStopToUser ?
                <div className="closest-stop-info">
                  Βρίσκεστε κοντά στην στάση
                  <button className="btn-link" onClick={() => this.changeStop(this.state.closestStopToUser.code)}>
                    {this.state.closestStopToUser.descr} ({this.state.closestStopToUser.id})
                  </button>
                </div> : ""}
              <Loader isLoading={!this.state.stopFeedLoaded}>
                <ErrorBoundary>
                  <LiveStopFeed
                    selectedStop={selectedStop}
                    linesFromSelectedStop={this.state.linesFromSelectedStop}
                    onLineSelect={this.changeLine}
                  />
                </ErrorBoundary>
              </Loader>
            </div>
            <div className="stops-col">
              <div id="scroll-to-stops-point"></div>
              <div className="title">Στάσεις</div>
              <Loader isLoading={!this.state.stopsLoaded}>
                <ErrorBoundary>
                  <Stops
                    selectedStop={selectedStop}
                    stops={this.state.stops}
                    onStopSelect={this.changeStop}
                  />
                </ErrorBoundary>
              </Loader>
            </div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  }
}
