import React from 'react';
import { Link } from 'react-router-dom';

import Loader from './Loader';
import StopsMap from './StopsMap';
import LiveStopFeed from './LiveStopFeed';
import ErrorBoundary from './ErrorBoundary';

import FavoriteStops from 'storage/favorite_stops';
import OasaAPI from 'third_party/oasa_api';

export default class StopDetails extends React.Component {
  state = {
    networkError: false,
    notFound: false,
    isFavorite: false,
    stop: null,
    stopLines: [],
    stopFeedLoaded: false,
  }

  changeLine = (lineCode) => {
    // @Todo Can we know which route this stop in on for each line? The following link
    // will be updated to require the route code as well to make sure the stop can
    // be selected at all times.
    this.props.history.push(`/line/${lineCode}?stop_code=${this.state.stop.code}`);
  }

  componentDidMount() {
    const stopCode = this.props.match.params.stopCode;

    OasaAPI.getStopDetails(stopCode)
      .then( stop => {
        if ( stop ) {
          this.setState({stop: stop})
        } else {
          FavoriteStops.delete(stopCode);
          this.setState({ notFound: true })
        }
      }).catch( e => this.setState({ networkError: true }) )

    OasaAPI.getStopRoutes(stopCode).then( stopLines => {
      this.setState({ stopLines: stopLines, stopFeedLoaded: true })
    });
  }

  render() {
    if ( this.state.notFound || this.state.networkError ) {
      return <>
        <div className="line-details-header">
          <div>
            <Link to={`/`}>επιστροφή</Link>
          </div>
        </div>

        <div className="error-box">
          { this.state.notFound ?
              "Δεν υπάρχει αυτή η στάση :(" :
              "Κάτι πήγε στραβά και δεν μπορέσαμε να φορτώσουμε το περιεχόμενο που βρισκόταν εδώ."
          }
        </div>
      </>
    }

    if ( this.state.stop ) {
      return (
        <>
          <div className="line-details-header">
            <div>
              <Link to={`/`}>επιστροφή</Link>
            </div>
          </div>
          <hr/>

          <div className="stops">
            <div className="stops-col">
              <Loader isLoading={!this.state.stopFeedLoaded}>
                <ErrorBoundary>
                  <LiveStopFeed
                    selectedStop={this.state.stop}
                    linesFromSelectedStop={this.state.stopLines}
                    onLineSelect={this.changeLine}
                  />
                </ErrorBoundary>
              </Loader>
            </div>
            <div className="stops-col">
              <ErrorBoundary>
                <StopsMap
                  stops={[this.state.stop]}
                  selectedStop={this.state.stop}
                />
              </ErrorBoundary>
            </div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  }
}
