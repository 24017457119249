import React from 'react';

// @Note onRouteSelect we want to try and retain the current stop (passing true)
//       We generally want this because OASA sometimes makes different routes
//       to mean alternative ones (on street market days for example), and they
//       don't deactivate the usual route. So for these cases we don't want to
//       reset the stop selection.
//
export default function RouteSelector(props) {
  return (
    <>
      {props.routes.map((route, i) => {
        let selected = (props.selectedRouteCode === route.code);
        return (
          <button
            key={route.code}
            onClick={() => props.onRouteSelect(route.code, true)}
            className={`btn-dark ${selected ? "" : "faded"}`}>
            {route.descr}
          </button>
        )
      })}
    </>
  );
}
