import React from 'react';

export default class LastUpdated extends React.Component {
  state = {
    minutesAgo: 0
  }

  componentDidMount() {
    this.updateMinutesAgo();
    this.timer = setInterval(this.updateMinutesAgo, 10000);
  }

  componentDidUpdate(prevProps, prevState) {
    if ( prevProps.timestamp !== this.props.timestamp ) {
      this.updateMinutesAgo();
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  cssClass = () => {
    if ( this.state.minutesAgo >= 4 ) {
      return "error";
    } else if ( this.state.minutesAgo > 1 ) {
      return "warning";
    } else {
      return "";
    }
  }

  updateMinutesAgo = () => {
    this.setState({
      minutesAgo: (this.props.timestamp === 0) ?
      -1 : Math.floor((Date.now() - this.props.timestamp) / 60000)
    })
  }

  message = () =>{
    if ( this.state.minutesAgo === -1 ) {
      return "φορτώνει..."
    } else if ( this.state.minutesAgo === 0 ) {
      return "τελευταία ενημέρωση πρίν από λίγα δευτερόλεπτα"
    } else if ( this.state.minutesAgo === 1 ) {
      return `τελευταία ενημέρωση πρίν από ${this.state.minutesAgo} λεπτό`
    } else if ( this.state.minutesAgo < 5 ) {
      return `τελευταία ενημέρωση πρίν από ${this.state.minutesAgo} λεπτά`
    } else {
      return `ΠΡΟΣΟΧΗ: τελευταία ενημέρωση πρίν από ${this.state.minutesAgo} λεπτά`
    }
  }

  render() {
    return <div className={`last-updated ${this.cssClass()}`}>
      {this.props.isLoading ?
        <i className="spinner"></i>
      : ""}
      {this.message()}
    </div>
  }
}
