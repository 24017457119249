import React from 'react';
import { Link } from 'react-router-dom';

import { CacheContext } from 'cache';
import FavoriteLines from 'storage/favorite_lines';
import FavoriteStops from 'storage/favorite_stops';
import Loader from './Loader';

import OasaAPI from 'third_party/oasa_api';

// @Note First column should be greek letters,
// second column should be latin letters.
const TransformTableForSearchForLineIDs = [
  [ "Α", ["a", "A"] ],
  [ "Β", ["b", "B"] ],
  [ "Γ", ["g", "G"] ],
  [ "Ε", ["e", "E"] ],
  [ "Τ", ["t", "T"] ],
  [ "Ν", ["n", "N"] ],
  [ "Χ", ["x", "X"] ],
]

function prepareSearchTextForLineIDs(str) {
  for(const transformData of TransformTableForSearchForLineIDs) {
    const keys = transformData[1];
    const replaceWith = transformData[0];

    for(const key of keys) {
      str = str.replace(key, replaceWith);
    }
  }

  return str;
}

export default class LineList extends React.Component {
  static contextType = CacheContext;

  state = {
    networkError: false,
    lines: [],
    favoriteLines: [],
    favoriteStops: [],
    linesLoaded: false,
    quickSearchText: "",
    typingTimeout: null,
    closestStopsOpen: false,
    closestStops: [],
    linesByClosestStop: {},
    lastStoredUserLocation: null,
    withLocationError: false,
  };

  filteredLines = () => {
    if ( this.state.quickSearchText && this.state.quickSearchText.length > 0 ) {
      const searchText = this.state.quickSearchText.toUpperCase().trim();
      const searchTextForLinesIDs = prepareSearchTextForLineIDs(searchText)

      return this.state.lines.filter(line => {
        return line.id.toUpperCase().includes(searchTextForLinesIDs) ||
          line.descr.toUpperCase().includes(searchText) ||
          line.descrEng.toUpperCase().includes(searchText);
      })
    } else {
      return this.state.lines;
    }
  }

  handleQuickSearchChange = (e) => {
    let inputText = e.target.value;

    clearTimeout(this.typingTimeout);
    this.typingTimeout = setTimeout(() => {
      this.setState({ quickSearchText: inputText });
    }, 200);
  }

  loadClosestStopsIfMovedEnough = (coords) => {
    // @Note For Athens, this distance is about 10 meters.
    const tooSmallMovementDistance = 0.000000012;
    const lastLocation = this.state.lastStoredUserLocation;
    const distanceMoved = lastLocation ?
      (lastLocation[0] - coords.latitude) * (lastLocation[0] - coords.latitude) +
      (lastLocation[1] - coords.longitude) * (lastLocation[1] - coords.longitude)
      : 999

    if ( distanceMoved > tooSmallMovementDistance ) {
      OasaAPI.getClosestStops(coords.latitude, coords.longitude).then(stops => {
        const closestStops = stops.slice(0, 4);

        this.setState({
          closestStops: closestStops,
          lastStoredUserLocation: [ coords.latitude, coords.longitude ],
          withLocationError: false,
        });

        for(const stop of closestStops) {
          OasaAPI.getStopRoutes(stop.code).then(stopLines => {
            this.setState({
              linesByClosestStop: {
                ...this.state.linesByClosestStop,
                [stop.code]: [...new Set(stopLines.map(l => l.id))]
              }
            });
          });
        }
      });
    }
  }

  toggleClosestStops = () => {
    if ( this.state.closestStopsOpen ) {
      this.setState({
        closestStopsOpen: false,
        closestStops: [],
        linesByClosestStop: {},
        lastStoredUserLocation: null,
      }, () => {
        navigator.geolocation.clearWatch(this.geolocationId);
      })
    } else {
      this.setState({
        closestStopsOpen: true
      }, () => {
        this.geolocationId = navigator.geolocation.watchPosition((pos) => {
          const coords = pos.coords;
          this.loadClosestStopsIfMovedEnough(coords);
        }, (err) => {
          this.setState({ withLocationError: true });
        }, {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        });
      })
    }
  }

  componentDidMount() {
    const cache = this.context;

    if ( cache.get("lines") ) {
      this.setState({ lines: cache.get("lines"), linesLoaded: true });
    } else {
      OasaAPI.getLines().then(lines => {
        cache.store("lines", lines);
        this.setState({ lines: lines, linesLoaded: true });
      }).catch(e => this.setState({ networkError: true }));
    }

    this.setState({
      favoriteLines: FavoriteLines.all(),
      favoriteStops: FavoriteStops.all()
    });
  }

  componentWillUnmount() {
    clearInterval(this.typingTimeout);
    navigator.geolocation.clearWatch(this.geolocationId);
  }

  render() {
    return (
      <>
        { this.state.favoriteStops.length || this.state.favoriteLines.length ?
          <h2>αγαπημένα</h2> : "" }

        { this.state.favoriteStops.length ?
          <div className="favorite-badge-list">
            {this.state.favoriteStops.map((stop, i) => {
              // @Todo Not super happy about exposing the : separator detail from the storage cache.
              const [stopCode, stopName] = stop.split(":");

              return (
                <Link key={stopCode} className="favorite-badge orange" to={`/stop/${stopCode}`}>{stopName}</Link>
              )
            })}
          </div> : "" }

        { this.state.favoriteLines.length ?
          <div className="favorite-badge-list">
            {this.state.favoriteLines.map((line, i) => {
              // @Todo Not super happy about exposing the : separator detail from the storage cache.
              const [lineCode, lineName] = line.split(":");

              return (
                <Link key={lineCode} className="favorite-badge" to={`/line/${lineCode}`}>{lineName}</Link>
              )
            })}
          </div> : "" }

        <h2 className={`closest-stops-dropdown ${this.state.closestStopsOpen ? "is-open" : ""}`} onClick={this.toggleClosestStops}>κοντινότερες στάσεις </h2>

        {this.state.closestStopsOpen ?
          <div className="closest-stops">
            {!this.state.withLocationError ?
                (this.state.closestStops.length > 0 ?
                (this.state.closestStops.map((stop) => {
                  const lines = this.state.linesByClosestStop[stop.code];

                  return (
                    <div key={stop.code} className="closest-stop-row">
                      <div className="stop-name">
                        <Link to={`/stop/${stop.code}`}>{stop.descr}</Link>
                        <div className="stop-code">({stop.code})</div>
                      </div>

                      <div className="stop-line-list">
                        {lines && lines.length > 0 ?
                            lines.map((line, i) => {
                              return (<div key={i} className="stop-line">{line}</div>)
                            }) : ""
                        }
                      </div>
                    </div>
                  )
                })
                ) : "φορτώνει..."
                ) : "δεν μπορέσαμε να βρούμε την τοποθεσία σας :("
            }
          </div> : ""}

        <h2>αναζητήστε γραμμή</h2>
        <input
          type="text"
          name="quick-search"
          className="quick-search"
          placeholder="αναζήτηση"
          autoComplete="off"
          onChange={this.handleQuickSearchChange} />

        <hr/>

        {!this.state.networkError ?
        <Loader isLoading={!this.state.linesLoaded}>
          <div className="line-list">
            {this.filteredLines().map((line, i) => {
              return (
                <Link key={line.code} className="line" to={`/line/${line.code}`}>
                  {line.id} - {line.descr}
                </Link>
              )
            })}
          </div>
        </Loader> :
        <div className="error-box">
          Κάτι πήγε στραβά και δεν μπορέσαμε να φορτώσουμε το περιεχόμενο που βρισκόταν εδώ.
        </div>
        }
      </>
    );
  }
}
