import React from 'react';
import { Icon as LeafletIcon } from 'leaflet';
import { Marker } from 'react-leaflet';

import OasaAPI from 'third_party/oasa_api';

import ImgBus from 'images/bus.svg'

const markerIcon = new LeafletIcon({
  iconUrl: ImgBus,
  iconSize: [35, 35],
  iconAnchor: [10, 30],
  popupAnchor: [3, -15]
});

export default class LiveBusMarkers extends React.Component {
  state = {
    busLocations: []
  }

  constructor(props) {
    super(props);

    this.liveBusTimer = null;
  }

  updateBusLocations = () => {
    if (this.props.selectedRouteCode) {
      OasaAPI.getBusLocations(this.props.selectedRouteCode)
        .then( busLocations => this.setState({ busLocations: busLocations }) );
    }
  }

  startTimer = () => {
    this.updateBusLocations();

    if ( !this.liveBusTimer ) {
      this.liveBusTimer = setInterval(this.updateBusLocations, 10000);
    }
  }

  stopTimer = () => {
    if ( this.liveBusTimer ) {
      clearInterval(this.liveBusTimer);
      this.liveBusTimer = null;
    }
  }

  handleVisibilityChange = () => {
    if ( document.hidden ) {
      this.stopTimer();
    } else {
      this.startTimer();
    }
  }

  componentDidMount() {
    this.startTimer();

    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedRouteCode !== prevProps.selectedRouteCode) {
      this.updateBusLocations();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    this.stopTimer();
  }

  render() {
    return (
      <>
        {this.state.busLocations.map((bus, i) => {
          return (
            <Marker
              zIndexOffset={-2}
              icon={markerIcon}
              key={`bus-${i}-${bus.vehicleNo}`}
              position={[bus.lat, bus.lng]}>
            </Marker>
          )
        })}
      </>
    );
  }
}
