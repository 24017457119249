import React from 'react';

export default class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // @Note I could log something here if I care.
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-box">
          Κάτι πήγε στραβά και δεν μπορέσαμε να φορτώσουμε το περιεχόμενο που βρισκόταν εδώ.
        </div>
      );
    }

    return this.props.children;
  }
}
