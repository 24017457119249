import React from 'react';

class Cache {
  constructor() {
    this.data = {}
  }

  store(key, value) {
    this.data[key] = value;
  }

  get(key) {
    return this.data[key] ? this.data[key] : null;
  }
}

export const CacheContext = React.createContext(new Cache());
