import React from 'react';

export default function NotFound(props) {
  return (
    <>
      <h1>404</h1>
      <div className="title">
        Η σελίδα που ζητήσατε δεν υπάρχει :(
      </div>
    </>
  )
}
