import React from 'react';

import TimeBadges from './TimeBadges'
import LastUpdated from './LastUpdated'

import FavoriteStops from 'storage/favorite_stops';
import OasaAPI from 'third_party/oasa_api';

import { ReactComponent as Star } from 'images/star.svg';

export default class LiveStopFeed extends React.Component {
  state = {
    stopArrivals: [],
    isFavorite: false,
    showingLineDescription: false,
    isFeedLoading: false,
    lastUpdatedTimestamp: 0,
  }

  constructor(props) {
    super(props);

    this.liveFeedTimer = null;
  }

  handleFavorite = () => {
    const stopCode = this.props.selectedStop.code;

    if ( FavoriteStops.includes(stopCode) ) {
      FavoriteStops.delete(stopCode);
      this.setState({ isFavorite: false })
    } else {
      FavoriteStops.store(stopCode, this.props.selectedStop.descr);
      this.setState({ isFavorite: true })
    }
  }

  setFavoriteState = () => {
    if ( this.props.selectedStop ) {
      this.setState({ isFavorite: FavoriteStops.includes(this.props.selectedStop.code) })
    }
  }

  updateStopArrivals = () => {
    if ( this.props.selectedStop ) {
      this.setState({ isFeedLoading: true })
      OasaAPI.getStopArrivals(this.props.selectedStop.code)
        .then( stopArrivals => this.setState({
          stopArrivals: stopArrivals,
          lastUpdatedTimestamp: Date.now(),
          isFeedLoading: false,
        }) )
    }
  }

  startTimer = () => {
    this.updateStopArrivals();

    if ( !this.liveFeedTimer ) {
      this.liveFeedTimer = setInterval(this.updateStopArrivals, 30000);
    }
  }

  stopTimer = () => {
    if ( this.liveFeedTimer ) {
      clearInterval(this.liveFeedTimer);
      this.liveFeedTimer = null;
    }
  }

  handleVisibilityChange = () => {
    if ( document.hidden ) {
      this.stopTimer();
    } else {
      this.startTimer();
    }
  }

  componentDidMount() {
    this.startTimer();
    this.setFavoriteState();

    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  }

  componentDidUpdate(prevProps, prevState) {
    const stopCode = (this.props.selectedStop || {}).code;
    const prevStopCode = (prevProps.selectedStop || {}).code;

    if ( stopCode !== prevStopCode ) {
      this.setFavoriteState();
      this.updateStopArrivals(stopCode);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    this.stopTimer();
  }

  render() {
    if ( this.props.selectedStop ) {
      return (
        <div className="stop-feed">
          {this.props.selectedStop ?
            <>
              <div className="title">
                <span
                  title={this.state.isFavorite ? "αφαίρεση από τα αγαπημένα" : "προσθήκη στα αγαπημένα"}
                  onClick={this.handleFavorite}>
                  <Star className={`favorite-star big-star ${this.state.isFavorite ? "active" : ""}`} />
                </span>
                { this.props.selectedStop.id } - { this.props.selectedStop.descr }

              </div>

              <div className="toggle-details-button">
                <button
                  className="btn-link"
                  onClick={() => { this.setState({ showingLineDescription: !this.state.showingLineDescription })}}>
                  { this.state.showingLineDescription ? "απόκρυψη λεπτομερειών" : "εμφάνιση λεπτομερειών"}
                </button>
              </div>
            </> : ""}

          {this.props.linesFromSelectedStop.length ?
            <>
              {this.props.linesFromSelectedStop.map((line, i) => {
                return (
                  <div key={i} className="stop-feed-row">
                    <div style={{minWidth: "6ch", textAlign: "right"}}>
                      <button
                        title={line.descr}
                        className="line-title btn-link"
                        onClick={() => this.props.onLineSelect(line.code)}
                      >
                        { line.id }
                      </button>
                    </div>

                    <div>
                      {this.state.showingLineDescription ?
                        <span className="line-description-badge">{line.descr}</span>
                        : <TimeBadges line={line} stopArrivals={this.state.stopArrivals} />
                      }
                    </div>
                  </div>
                );
              })}
              <LastUpdated
                timestamp={this.state.lastUpdatedTimestamp}
                isLoading={this.state.isFeedLoading}
              />
            </> : <small>Δεν περνάνε λεωφορεία απο αυτή τη στάση.</small>}
        </div>
      );
    } else {
      return (
        <div className="stop-feed">
          <small>
            Κάντε κλικ σε μία στάση στη λίστα ή τον χάρτη για να δείτε ποια
            λεωφορεία κατευθύνονται προς αυτήν.
          </small>
        </div>
      )
    }
  };
}
