const LOCAL_STORAGE_KEY = "favorite-stops";

export default class FavoriteStops {
  static store(stopCode, stopName) {
    const valueToStore = `${stopCode}:${stopName}`;
    const storedStopsStr = localStorage.getItem(LOCAL_STORAGE_KEY);

    if ( storedStopsStr ) {
      let storedStops = storedStopsStr.split(",");

      // @Note Remove any badly formed stop
      storedStops = storedStops.filter(stop => stop.includes(":"))

      if ( !storedStops.includes(valueToStore) ) {
        storedStops.push(valueToStore);
      }

      localStorage.setItem(LOCAL_STORAGE_KEY, storedStops.join(","));
    } else {
      localStorage.setItem(LOCAL_STORAGE_KEY, valueToStore);
    }
  }

  static includes(stopCode) {
    return FavoriteStops.all().find(l => l.split(":")[0] === stopCode);
  }

  static delete(stopCode) {
    const stops = FavoriteStops.all().filter(s => s.split(":")[0] !== stopCode);
    localStorage.setItem(LOCAL_STORAGE_KEY, stops.join(","));
  }

  static clear() {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  }

  static all() {
    const storedStopsStr = localStorage.getItem(LOCAL_STORAGE_KEY);

    if ( storedStopsStr ) {
      return storedStopsStr.split(",");
    } else {
      return []
    }
  }
}
